import React, { useState } from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Modal from 'react-modal';

import { Icon } from 'react-icons-kit';
import { cross } from 'react-icons-kit/icomoon/cross'

import Video from "../components/video"

import "../containers/aboutvideo.css"
import "../containers/howtovideos.css"

const HowToVideos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulVideos {
          nodes {
            id
            title
            url
            previewImage {
              file {
                url
              }
            }
          }
        }
      }
    `
  )

  const [modalIsOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);


  function openModal(videoUrl, videoTitle) {
    setVideoUrl(videoUrl);
    setVideoTitle(videoTitle);
    setIsOpen(true);
  }

  function closeModal() {
    setVideoUrl(null);
    setVideoTitle(null);
    setIsOpen(false);
  }

  return (
    <Layout page="internal">
      <Seo title="How To Videos" />
      <Container className="container">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <Icon icon={cross} className="modalCloseBtn" onClick={closeModal} size="25" />
          <Video
            videoSrcURL={`https://www.youtube.com/embed/${videoUrl}`}
            videoTitle={videoTitle}
          />
        </Modal>
        <div className="customWrapper">
          <div className="defaultWrapper">
            <h1>How To Videos</h1>
            {data.allContentfulVideos.nodes.map(node => {
              return (
                <div className="howtoVideoBlock" key={node.id}>
                  <h2>{node.title}</h2>
                  <a href="#" onClick={() => openModal(node.url, node.title)}>
                    <div className="aboutVideoBlock">
                      <img
                        src={node.previewImage.file.url}
                        alt={node.title}
                        width="100%"
                        height="auto"
                        className="previewImage"
                      >
                      </img>
                      <StaticImage
                        src="../images/youtube-play.svg"
                        alt="Youtube Play Button"
                        className="playbutton"
                      />
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Layout>
  )

}

export default HowToVideos